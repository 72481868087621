
<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-2/4 xl:w-1/3 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="bg-white">
            <div class="px-8 pt-8 login-tabs-container">
              <div class="vx-card__title my-4 text-center">
                <!-- <a class="w-full mb-3 flex justify-center" :href="absoluteUrl()">
                  <img height="30" :src="absoluteUrl('/images/logo/designkago-black.png')"/>
                </a> -->
                <p class="">Please login with email and password</p>
              </div>

              <vs-tabs>
                <vs-tab label="Login">
                    <div>
                      <vs-input
                        v-validate="'required|email|min:4|max:40'"
                        name="email"
                        icon-no-border
                        icon="icon icon-user"
                        icon-pack="feather"
                        label="Email"
                        placeholder="Enter email"
                        v-model="data.email"
                        @keyup.enter="submitData"
                        class="w-full"/>
                      <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>

                      <vs-input
                        v-validate="'required|min:4|max:90'"
                        type="password"
                        name="password"
                        icon-no-border
                        icon="icon icon-lock"
                        icon-pack="feather"
                        label="Password"
                        placeholder="Enter password"
                        v-model="data.password"
                        @keyup.enter="submitData"
                        class="w-full mt-6" />
                      <span class="text-danger text-sm" v-show="errors.has('password')" >{{ errors.first('password') }}</span>

                      <div class="mt-8 mb-5">
                          <vs-checkbox v-model="data.remember" class="mb-0">Remember Me</vs-checkbox>
                      </div>
                      <div class="flex flex-wrap mb-3">
                        <vs-button class="block w-full" @click="submitData">Login</vs-button>
                      </div>
                    </div>

                </vs-tab>
              </vs-tabs>

            </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>


<script>

export default {
  data() {
    return {
      data: {
        email: '',
        password: '',
        role: "admin",
        remember: false
      },

    }
  },
  methods: {
    submitData(){
      this.$validator.validateAll().then(result => {
        if (!result) { this.alertError("Form Not Valid"); return;}
        this.$vs.loading({color: "#444", type: "material"})
        let formData = new FormData();
        formData.append('data', JSON.stringify(this.data));
        this.$http.post(this.absoluteUrl('/api/login'), formData)
        .then((response)=>{
          this.$vs.loading.close()
          if(response.data.success){
            this.resetForm()
            this.$store.commit("SET_AUTH_TOKEN", response.data.data)
            window.location = "/";
          }
        })
      })
    },
    resetForm(){
      this.data = {
        email: '',
        password: '',
        role: "admin",
        remember: false
      }
      this.$validator.reset()
    }
   },

  }
</script>

<style lang="scss">
.login-tabs-container {
  min-height: 450px;
  color:#000000;

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
}
</style>
